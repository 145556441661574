<template>
  <section class="screen-panel-editor" v-if="isEnabled">
    <KeyboardHandler ref="kb">
      <DashboardEquipment
        v-bind:equipmentId="connectorId"
        v-bind:mode="dashboardMode"
        v-bind:panelName="panelName"
        v-bind:screenId="screenId"
      />
      <!-- <Alert v-else-if="template" type="warning" style="margin-top: 2rem">
        {{ $tc("ref_map_not_found", 1) }}
        <router-link to="/dashboard/screen/edit/layout" class="alert-link">
          {{ $tc("ref_map_not_found", 2) }}
        </router-link>
      </Alert> -->
    </KeyboardHandler>
  </section>
  <Alert v-else-if="screen" type="warning" style="margin-top: 2rem">
    <p class="text-black">
      {{ $tc("titles.you_dont_have_permission", 1) }}
    </p>
    <router-link to="/dashboard/screen/edit/layout" class="alert-link">
      {{ $tc("back", 2) }}
    </router-link>
  </Alert>
</template>

<script>
import DashboardEquipment from "./DashboardEquipment.vue";
import KeyboardHandler from "@/components/editor/keyboard-handler.vue";
import Alert from "@/components/widgets/alert";
import Panels from "@/assets/dashboard/panels.json";
import isEqual from "lodash/isEqual";

export default {
  name: "DashboardScreenPanelEditor",
  components: {
    DashboardEquipment,
    KeyboardHandler,
    Alert
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: () => "viewer"
    },
    screenId: {
      type: String,
      required: true,
      default: () => ""
    },
    panelName: {
      type: String,
      required: true,
      default: () => ""
    }
  },
  data() {
    return {
      // template: null,
      done: [],
      undone: [],
      newMutation: true,
      templateStatus: "idle",
      controlsIgnoredUpdates: {}
    };
  },
  computed: {
    contractId() {
      return this.$store.getters["user/loggedUser"]?.contract_id || "";
    },
    isEnabled() {
      if (this?.screen) {
        if (!this?.screen?.public) {
          return true;
        }
      }
      return false;
    },
    dashboardMode() {
      return this.mode == "edit" ? "editor" : "viewer";
    },
    screen() {
      return this.$store.getters["dashboard/screen"](this.screenId);
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || [];
    },
    connectorId() {
      let refmap =
        this.$store.getters["dashboard/screenRefMap"](this.screenId) || null;
      if (refmap && refmap.conn1) {
        return refmap.conn1;
      }
      let lst = this?.screen?.reference_connectors || [];
      if (lst.length) {
        return lst[0].id;
      }
      return undefined;
    },
    canRedo() {
      return this.undone.length;
    },
    canUndo() {
      return this.done.length > 1;
    },
    draft() {
      let draft = this.$store.getters["dashboard/draft"] || null;
      return draft && draft?.screenId == this.screenId ? draft : null;
    },
    template() {
      return this?.draft ? this.draft.template : null;
    },
    connectorDataList() {
      return this.connectorId
        ? (this.$store.getters["dashboard/dataList"] || []).filter(
            ({ clp_id }) => parseInt(clp_id) == parseInt(this.connectorId)
          )
        : [];
    }
  },
  watch: {
    screen: {
      handler(n, o) {
        if (n) {
          if (!this.template) {
            this.$store.dispatch("dashboard/initDraft", this.screenId);
          }
        }
      },
      deep: true
    },
    connectorId: {
      handler(n, o) {
        if (n) {
          if (o && parseInt(n) != parseInt(o)) {
            this.resetDisplay();
          }
          if (!this.connectorDataList.length) {
            this.fetchConnectorDataList();
          }
        }
        this.$emit("equipmentChanged", n);
      },
      immediate: true
    }
  },
  methods: {
    fetchScreens() {
      this.$store.dispatch("dashboard/fetchScreens", {
        contract_id: this.contractId
      });
    },
    undo(commitNext) {
      if (this.canUndo) {
        if (!commitNext) this.undone.push(this.done.pop());
        let mutation = this.done.pop();
        this.newMutation = false;
        if (typeof mutation.payload == "object") {
          if (
            mutation.type == "synoptic/UPDATE_CONTROL_BY_ID" &&
            (mutation?.payload?.control?.id || "")
          ) {
            // if mutation changes should be ignored
            // skips recommit of it
            const control = mutation.payload.control;
            if (
              isEqual(this.controlsIgnoredUpdates[control.id]?.at(-1), control)
            ) {
              this.controlsIgnoredUpdates[control.id].pop();
              this.undo(true);
              return;
            }
            this.$store.commit("synoptic/SELECT_CONTROL", control.id);
          } else if (
            [
              "synoptic/UPDATE_CONTROLS",
              "synoptic/REPLACE_SINGLE_UPDATE"
            ].includes(mutation.type)
          ) {
            this.$store.commit("synoptic/CLEAR_SELECTED_CONTROLS");
            mutation.payload.controls.forEach(({ id }) =>
              this.$store.commit("synoptic/ADD_CONTROL_TO_SELECTION", id)
            );
          }
          this.$store.commit(
            `${mutation.type}`,
            Object.assign({}, mutation.payload)
          );
          if (mutation.dismissable) {
            this.undo();
          }
        } else {
          this.$store.commit(`${mutation.type}`, mutation.payload);
        }
        this.newMutation = true;
        this.$set(this, "undone", this.undone);
        this.$set(this, "done", this.done);
      }
    },
    redo() {
      if (this.canRedo) {
        let mutation = this.undone.pop();
        this.newMutation = false;
        switch (typeof mutation.payload) {
          case "object":
            this.$store.commit(
              `${mutation.type}`,
              Object.assign({}, mutation.payload)
            );
            if (
              mutation.type == "synoptic/UPDATE_CONTROL_BY_ID" &&
              (mutation?.payload?.control?.id || "")
            ) {
              this.$store.commit(
                "synoptic/SELECT_CONTROL",
                mutation.payload.control.id
              );
            }
            if (
              mutation.dismissable ||
              mutation.type == "synoptic/REPLACE_SINGLE_UPDATE"
            ) {
              this.redo();
            }
            break;
          default:
            this.$store.commit(`${mutation.type}`, mutation.payload);
        }
        this.newMutation = true;
        this.$set(this, "undone", this.undone);
        this.$set(this, "done", this.done);
      }
    },
    panelFocus() {
      if (this?.$refs?.kb || null) {
        let x = window.scrollX,
          y = window.scrollY;
        this.$refs.kb.$el.focus();
        window.scrollTo(x, y);
      }
    },
    resetDisplay() {
      if (this.connectorId) {
        this.$store.commit("RESET_DISPLAY", { equipmentId: this.connectorId });
      }
    },
    addPanel(template, row, col) {
      let layout = template.layout || [];
      if (col == -1) {
        layout[row].push({ panels: [], width: 0 });
        let len = layout[row].length;
        let width = Math.round((100 / len) * 100) / 100;
        layout[row].forEach((column) => {
          column.width = width;
        });
        col = len - 1;
      }
      let panel = Panels.find(
        (item) => item.template.template == "SynopticPanel"
      ).template;
      layout[row][col].panels.push(panel.name);

      // assign dynamic name
      template.panels.push(panel);

      this.$store.commit("dashboard/SAVE_DRAFT", {
        screenId: this.screen.id,
        template: template
      });
    },
    addRow(row) {
      let template = JSON.parse(JSON.stringify(this.draft.template));
      let layout = template.layout || [];
      if (row == -1) {
        layout.push([]);
        this.addPanel(template, layout.length - 1, -1);
      } else {
        if (row < layout.length) {
          layout.splice(row, 0, []);
          this.addPanel(template, row, -1);
        }
      }
    },
    fetchConnectorDataList() {
      return this.$store.dispatch("dashboard/fetchResourcesFrom", {
        resource: "data",
        connectorId: this.connectorId,
        forceUpdate: false,
        once: true
      });
    }
  },
  created() {
    let self = this;
    this.$store.dispatch("dashboard/resetEditor");
    if (!(this.$store.getters["equipmentList"] || []).length) {
      this.$store.dispatch("fetchEquipmentList");
    }
    this.$store.commit("dashboard/SET_MODE", this.dashboardMode);
    this.resetDisplay();
    this.$root.$on("undo", this.undo);
    this.$root.$on("redo", this.redo);
    this.$root.$on("panelFocus", this.panelFocus);

    this.$root.$on("dashboard:addRow", this.addRow);

    this.$store.subscribe((mutation) => {
      if (self.dashboardMode == "editor") {
        //console.log(mutation.type);
        switch (mutation.type) {
          case "synoptic/RESET_STATE":
            self.done = []; // since there was already a warning, just initialize it.
            this.undone = [];
            break;
          case "synoptic/SET_PANEL":
            // keep history if it was initialized once
            if (mutation.payload && self.newMutation && !self.canUndo) {
              self.done = [JSON.parse(JSON.stringify(mutation))];
              this.undone = [];
            }
            break;
          case "synoptic/REPLACE_SINGLE_UPDATE":
            if (this.newMutation) {
              // add controls to ignore list so
              // single updates are not rerun
              mutation.payload.controls.forEach((control) => {
                this.controlsIgnoredUpdates[control.id] = (
                  this.controlsIgnoredUpdates[control.id] ?? []
                ).concat(JSON.parse(JSON.stringify(control)));
              });
            }
          // eslint-disable-next-line no-fallthrough
          case "synoptic/SNAPSHOT":
          case "synoptic/UPDATE_CONTROL_BY_ID":
          case "synoptic/UPDATE_CONTROLS":
            if (!mutation.payload.noRecord)
              self.done.push(JSON.parse(JSON.stringify(mutation)));
            break;
          case "synoptic/DISMISS":
            if ((self.done || []).length) {
              self.done[self.done.length - 1].dismissable = true;
            }
            break;
        }
      }
    });

    if (!this.screen) {
      this.fetchScreens();
      return;
    } else if (!(this.screen?.template || null)) {
      this.$store.dispatch("dashboard/initDraft", this.screenId);
      //this.fetchTemplate();
    } else {
      //this.template = this.screen.template;
      this.ready = true;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("history/reset");
    this.$root.$emit("controlSidebar:setContent", null);
    this.$root.$off("undo", this.undo);
    this.$root.$off("redo", this.redo);
    this.$root.$off("panelFocus", this.panelFocus);

    this.$root.$off("dashboard:addRow", this.addRow);
  }
};
</script>
<style scoped>
.fa-redo {
  transform: rotateY(180deg);
}
</style>
